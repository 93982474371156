.c-swipe {
  overflow: hidden;
}

.c-swipe-wrapper {
  height: 100%;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
}

.c-swipe-item,
.c-swipe-item-copy {
  width: 100%;
  height: 100%;
  -ms-flex: none;
  -webkit-box-flex: 0;
  flex: none;
}

.c-swipe-pagination {
  position: relative;
  height: 0;
}

.c-swipe-pagination-bar {
  position: absolute;
  left: 0;
  right: 0;
  top: -0.12rem;
  bottom: 0;
  height: 0.04rem;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  justify-content: center;
  -ms-flex-align: start;
  -webkit-box-align: start;
  align-items: flex-start;
}

.c-swipe-pagination-item {
  display: block;
  width: 0.08rem;
  height: 0.04rem;
  border-radius: 0.03rem;
  background-color: #b5b5b5;
  margin: 0 0.03rem;
  -webkit-transition: all .1s;
  transition: all .1s;
}

.c-swipe-pagination-item.active {
  width: 0.2rem;
  background-color: #48a3f1;
}