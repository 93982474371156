.c-banner-advertisement .banner-swipe .wrap {
  height: -webkit-calc(100vw / 1.9255813953488372);
  height: calc(100vw / 1.9255813953488372);
  position: relative;
  display: block;
}

.c-banner-advertisement .banner-swipe .wrap .title {
  position: absolute;
  bottom: 0.16rem;
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 0.28rem;
}

.c-banner-advertisement .banner-swipe .wrap .ad {
  background: no-repeat center / cover;
  width: 100%;
  height: 100%;
}