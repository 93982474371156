.c-detail-ad {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  margin-top: 0.1rem;
  padding: 0.2rem;
  background-color: #fff;
  height: -webkit-calc((100vw + 0.4rem) / 3.923076923076923);
  height: calc((100vw + 0.4rem) / 3.923076923076923);
}

.c-detail-ad > .item {
  display: block;
  margin-right: 0.1rem;
  width: 100%;
}

.c-detail-ad > .item:last-child {
  margin-right: 0;
}

.c-detail-ad > .item .img {
  width: 100%;
  height: 100%;
  background: no-repeat center;
  background-size: cover;
}